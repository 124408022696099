import type { UserProfile } from '~/types';
import { BaseModel } from '~/models/base-model';

export class UserModel extends BaseModel<UserProfile> {
    email = '';
    firstName = '';
    lastName = '';
    avatarUrl = '';
    gender = '';
    phone = null;
    dateOfBirth = null;
    customerSince = '';
    defaultShippingAddressUid = null;
    defaultBillingAddressUid = null;
    currentContextId = '';
    stripeCustomerId = '';
    availableContexts = [];
}
